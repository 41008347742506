
import { ProductsCard } from "@/models/entities/products-card.interface";
import { productPhoto } from "@/services/api/productPhoto.service";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
import { ADMIN, CLIENT, OPERATOR, PUBLIC } from "@/helpers/roles";
import AuthModule from "@/store/modules/auth.module";
import { orders } from "@/services/api/orders.service";
import CartModule from "@/store/modules/cart.module";
import { CartProduct } from "@/models/entities/cart-product.interface";
import { productPriceBooks } from "@/services/api/productPriceBooks.service";
import SnackbarModule from "@/store/modules/snackbar.module";
const cartModule = getModule(CartModule, store);
const overlayModule = getModule(OverlayModule, store);
const authModule = getModule(AuthModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class LLProductCard extends Vue {
  /** Sets the product value */
  @Prop({ required: true }) private product: ProductsCard;
  /** Sets if the page is a preview or not */
  @Prop({ required: true }) private isPreview: boolean;

  private quantity = 1;
  private selectedMeasureUnit = null;
  private prices = {
    price: null,
    priceInPromo: null,
    isInPromo: false,
    total: 0,
  };
  private img: string = null;
  private photoProgress = false;
  private isFavorite = false;

  @Watch("product")
  private async isProductChanged(): Promise<void> {
    await this.photo();
  }

  get overlay(): boolean {
    return overlayModule.status;
  }

  get discount(): string {
    return (
      100 -
      (this.prices.priceInPromo * 100) / this.prices.price
    ).toFixed();
  }

  mounted(): void {
    this.setDefaultValue();
    this.isProductChanged();
    this.isFavorite = this.product.isFavorite;
  }

  private setDefaultValue(): void {
    this.$set(this.prices, "price", this.product.prices[0].price);
    this.$set(this.prices, "priceInPromo", this.product.prices[0].priceInPromo);
    this.$set(this.prices, "isInPromo", this.product.prices[0].isInPromo);
    if (this.prices.isInPromo) {
      this.$set(this.prices, "total", this.prices.priceInPromo * this.quantity);
    } else {
      this.$set(this.prices, "total", this.prices.price * this.quantity);
    }
    this.selectedMeasureUnit = this.product.prices[0].measureUnitTypeID;
  }

  private onChangeMeasureUnit(): void {
    const p = this.product.prices.find(
      (price) => price.measureUnitTypeID == this.selectedMeasureUnit
    );
    if (p) {
      this.$set(this.prices, "price", p.price);
      this.$set(this.prices, "priceInPromo", p.priceInPromo);
      this.$set(this.prices, "isInPromo", p.isInPromo);
      if (!this.quantity || this.quantity <= 0) this.quantity = 1;
      if (this.prices.isInPromo) {
        this.$set(
          this.prices,
          "total",
          this.prices.priceInPromo * this.quantity
        );
      } else {
        this.$set(this.prices, "total", this.prices.price * this.quantity);
      }
      this.photo();
    }
  }

  private async photo(): Promise<void> {
    this.photoProgress = true;
    try {
      let photo = (
        await productPhoto.getPhotoByMeasureUnitTypeID(
          this.product.productPriceBookID,
          this.selectedMeasureUnit
        )
      ).data;
      if (photo.byteLength == 0) {
        photo = (await productPhoto.getDefaultPhotoByProductId(this.product.id))
          .data;
      }
      let p = new File([photo], "photo");
      var binaryData = [];
      if (p) {
        binaryData.push(p);
        this.img = window.URL.createObjectURL(
          new Blob(binaryData, { type: "image/png" })
        );
      }
    } catch (error) {
      console.error;
    }
    this.photoProgress = false;
  }

  private async onFavorite(): Promise<void> {
    if (authModule.role === CLIENT || authModule.role === PUBLIC) {
      overlayModule.showOverlay();
      await productPriceBooks.updateProductPriceBookIsFavorite(
        this.product.productPriceBookID
      );
      this.isFavorite = !this.isFavorite;
      this.$emit("favorite");
    } else {
      snackbarModule.showSnackbar({
        message:
          "Non è possibile salvare dei preferiti come operatore o amministratore",
        type: "warning",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 2000);
    }
  }

  private async onCartClick(): Promise<void> {
    const product = {} as CartProduct;
    this.$set(product, "productPriceBookID", this.product.productPriceBookID);
    this.$set(product, "quantity", this.quantity);
    this.$set(product, "measureUnitTypeID", this.selectedMeasureUnit);
    if (authModule.role === PUBLIC) {
      this.$router.push({
        name: "login",
        params: { product: JSON.stringify(product) },
      });
    } else if (authModule.role === CLIENT) {
      await orders.addOrder(product);
      cartModule.add(product);
      snackbarModule.showSnackbar({
        message: "Prodotto aggiunto al carrello",
        type: "success",
      });
      setTimeout(() => snackbarModule.hideSnackbar(), 1500);
    } else if (authModule.role === ADMIN || authModule.role === OPERATOR) {
      if (this.$route.name == "customers") {
        this.$set(product, "productName", this.product.productName);
        let p = this.product.prices.find(
          (el) => el.measureUnitTypeID == this.selectedMeasureUnit
        );
        if (p) {
          this.$set(product, "price", p.price);
          this.$set(product, "isInPromo", p.isInPromo);
          this.$set(product, "priceInPromo", p.priceInPromo);
          this.$set(product, "measureUnitTypeName", p.measureUnitTypeName);
        }
        cartModule.insertOrUpdate(product);
      } else if (this.$route.name == "preview") {
        snackbarModule.showSnackbar({
          message:
            "Non è possibile aggiungere prodotti al carrello in anteprima",
          type: "warning",
        });
      }
    }
    this.quantity = 1;
  }

  changeFavorite(): void {
    this.isFavorite = !this.isFavorite;
  }
}
