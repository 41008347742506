
import { OrderProduct } from "@/models/entities/order-product.interface";
import { Order } from "@/models/entities/order.interface";
import { DataTable } from "@/models/utils/datatable.interface";
import { CellType, Header } from "@/models/utils/header.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OrderDetails extends Vue {
  /** Sets the order details value */
  @Prop({ required: true }) private order: Order;
  /** Sets the order products value */
  @Prop({ required: true }) private products: OrderProduct[];
  /** Sets the if order is from myOrders page */
  @Prop({ required: false, default: false }) private isFromMyOrders: boolean;

  private headers: Header[] = [
    {
      text: "Prodotti",
      align: "start",
      filterable: true,
      value: "productName",
      cellType: CellType.DEFAULT,
      isPrimary: true,
      class: "llDataTable_header black--text",
    },
    {
      text: "Unità di misura",
      align: "start",
      filterable: true,
      value: "measureUnitTypeName",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Quantità",
      align: "start",
      filterable: true,
      value: "quantity",
      cellType: CellType.DEFAULT,
      isPrimary: false,
      class: "llDataTable_header",
    },
    {
      text: "Prezzo d'acquisto",
      align: "start",
      filterable: true,
      value: "purchasePrice",
      condition: "isInPromo",
      customProp: "initialPrice",
      cellType: CellType.PRICEWITHPROMO,
      isPrimary: false,
      class: "llDataTable_header",
    },
  ];
  public items: DataTable<unknown> = {
    key: "index",
    loading: true,
    headers: this.headers,
    values: [],
    search: "",
    actions: [],
  };

  mounted(): void {
    this.getTableItems();
  }

  getTableItems(): void {
    this.items.loading = false;
    this.$set(this.items, "values", this.products);
  }

  private getMobileOrderStatusLabel(item: Order): string {
    if (item.isCanceled) {
      if (item.isCanceledByAdmin) {
        return "Respinto";
      } else {
        return "Annullato";
      }
    } else if (item.isDefinitevelyConfirmed) {
      return "Confermato";
    }
    return "Da confermare";
  }

  private getMobileOrderStatusIcon(item: Order): string {
    if (item.isCanceled) {
      if (item.isCanceledByAdmin) {
        return "mdi-clipboard-text-off-outline";
      } else {
        return "mdi-close-circle-outline";
      }
    } else if (item.isDefinitevelyConfirmed) {
      return "mdi-check-circle-outline";
    }
    return "mdi-clipboard-clock-outline";
  }

  private getMobileOrderStatusIconColor(item: Order): string {
    if (item.isCanceled) {
      if (item.isCanceledByAdmin) {
        return "error";
      } else {
        return "error darken-2";
      }
    } else if (item.isDefinitevelyConfirmed) {
      return "success";
    }
    return "accent";
  }
}
