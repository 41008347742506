
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { OrderFiltersBtn } from "@/models/utils/order-filters-btn.interface";
import { OrderStatus } from "@/models/utils/order-status.enum";

@Component
export default class OrdersFilters extends Vue {
  /** Sets the orders status */
  @PropSync("status") private statusValue: OrderStatus;
  /** Sets the from date */
  @PropSync("fromDate") private from: string;
  /** Sets the to date */
  @PropSync("toDate") private to: string;
  /** Sets the if order is from myOrders page */
  @Prop({ required: false, default: false }) private isFromMyOrders: boolean;

  private dates = {
    from: {
      show: false,
      value: null,
    },
    to: {
      show: false,
      value: null,
    },
  };
  private loading = false;
  private buttons = [] as OrderFiltersBtn[];
  private myOrdersBtn = [
    {
      value: OrderStatus.CANCELED,
      tooltip: "Annullato",
      color: "error darken-2",
      icon: "mdi-close-circle-outline",
    },
    {
      value: OrderStatus.REJECTED,
      tooltip: "Respinto",
      color: "error",
      icon: "mdi-clipboard-text-off-outline",
    },
    {
      value: OrderStatus.CONFIRMED,
      tooltip: "Da confermare",
      color: "accent",
      icon: "mdi-clipboard-clock-outline",
    },
    {
      value: OrderStatus.DEFINITEVELY_CONFIRMED,
      tooltip: "Confermato",
      color: "success",
      icon: "mdi-check-circle-outline",
    },
  ];
  private ordersBtn = [
    {
      value: OrderStatus.CANCELED,
      tooltip: "Annullato",
      color: "error darken-2",
      icon: "mdi-close-circle-outline",
    },
    {
      value: OrderStatus.REJECTED,
      tooltip: "Respinto",
      color: "error",
      icon: "mdi-clipboard-text-off-outline",
    },
    {
      value: OrderStatus.INPROGRESS,
      tooltip: "In corso",
      color: "accent darken-2",
      icon: "mdi-cart-arrow-down",
    },
    {
      value: OrderStatus.CONFIRMED,
      tooltip: "Da confermare",
      color: "accent",
      icon: "mdi-clipboard-clock-outline",
    },
    {
      value: OrderStatus.DEFINITEVELY_CONFIRMED,
      tooltip: "Confermato",
      color: "success",
      icon: "mdi-check-circle-outline",
    },
  ];

  mounted(): void {
    this.$set(
      this.dates.from,
      "value",
      this.computedFromDate.toDate("dd/MM/yyyy").toFormat()
    );
    this.$set(
      this.dates.to,
      "value",
      this.computedToDate.toDate("dd/MM/yyyy").toFormat()
    );
    if (this.isFromMyOrders) {
      this.buttons = this.myOrdersBtn;
    } else {
      this.buttons = this.ordersBtn;
    }
  }

  get computedFromDate(): string {
    let date = new Date(this.from);
    if (this.dates.from.value) {
      date = new Date(this.dates.from.value);
    }
    return date.toFormat("dd/MM/yyyy");
  }

  get computedToDate(): string {
    let date = new Date(this.to);
    if (this.dates.to.value) {
      date = new Date(this.dates.to.value);
    }
    return date.toFormat("dd/MM/yyyy");
  }

  private onInputDate(): void {
    this.$set(this.dates.from, "show", false);
    this.$set(this.dates.to, "show", false);
    if (this.dates.from.value > this.dates.to.value) {
      this.$set(this.dates.to, "value", this.dates.from.value);
    }
    this.from = this.dates.from.value;
    this.to = this.dates.to.value;
    this.$emit("change");
  }

  private getBtnToggle(status: OrderStatus): string {
    let color = "";
    switch (status) {
      case OrderStatus.CANCELED:
        color = "error darken-2";
        break;
      case OrderStatus.REJECTED:
        color = "error";
        break;
      case OrderStatus.INPROGRESS:
        color = "accent darken-2";
        break;
      case OrderStatus.CONFIRMED:
        color = "accent";
        break;
      case OrderStatus.DEFINITEVELY_CONFIRMED:
        color = "success";
        break;
      case OrderStatus.NONE:
        color = "";
        break;
    }
    return color;
  }

  private downloadPDF() {
    this.loading = true;
    this.$emit("downloadPDF");
    setTimeout(() => (this.loading = false), 1500);
  }
}
